<template>
  <CContainer>
    <CRow>
      <CCol col="6">
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <strong>Configuracion Boton de PSE e Impuestos</strong>
            </CCardHeader>
            <CCardBody>
              <CForm validate @submit="actualizarUsuario">
                <CInput
                  label="Valor Credito Cliente"
                  :ref="key"
                  v-model="form.valor_credito"
                  prepend="$"
                  required
                  was-validated
                >
                </CInput>
                <CSelect
                  label="Genera Factura Billetera"
                  placeholder="Genera Factura Billetera"
                  :options="[
                    { value: 'SI', label: 'SI' },
                    { value: 'NO', label: 'NO' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.genera_factura"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CSelect
                  label="Tipo de Cliente"
                  placeholder="Tipo de Cliente"
                  :options="[
                    { value: 'natura', label: 'Persona Natural' },
                    { value: 'juridica', label: 'Persona Juridica' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.tipo_cliente"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CInput
                  label="Nombre del Cliente"
                  placeholder="Nombre del Cliente"
                  autocomplete="off"
                  minlength="3"
                  required
                  was-validated
                  v-model="form.nombre_cliente"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <CSelect
                  label="Tipo de Documento"
                  placeholder="Tipo de Documento"
                  :options="[
                    { value: 'cc', label: 'Cedula' },
                    { value: 'nit', label: 'Nit' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.tipo_documento"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CInput
                  label="Numero del Documento"
                  placeholder="Numero del Documento"
                  autocomplete="off"
                  @keypress="isNumber($event)"
                  minlength="3"
                  required
                  was-validated
                  v-model="form.numero_documento"
                >
                  <template #prepend-content
                    ><CIcon name="cil-phone"
                  /></template>
                </CInput>
                <CInput
                  label="Digito Verificacion"
                  placeholder="Digito Verificacion"
                  autocomplete="off"
                  @keypress="isNumber($event)"
                  minlength="1"
                  required
                  was-validated
                  v-model="form.digito_verificacion"
                >
                  <template #prepend-content
                    ><CIcon name="cil-phone"
                  /></template>
                </CInput>
                <CInput
                  label="Celular (Con Indicativo del Pais)"
                  placeholder="Celular (Con Indicativo del Pais)"
                  autocomplete="off"
                  @keypress="isNumber($event)"
                  minlength="11"
                  maxlength="12"
                  required
                  was-validated
                  v-model="form.celular"
                >
                  <template #prepend-content
                    ><CIcon name="cil-phone"
                  /></template>
                </CInput>
                <CInput
                  label="Direccion del Cliente"
                  placeholder="Direccion del Cliente"
                  autocomplete="off"
                  minlength="15"
                  was-validated
                  v-model="form.direccion_cliente"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <hr />
                <CRow>
                  <CCol col="6">
                    <router-link to="/usuarios">
                      <CButton color="primary" block>Atras</CButton>
                    </router-link>
                  </CCol>
                  <CCol col="6">
                    <CButton color="success" type="submit" block
                      >Actualizar</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <!--<CCardFooter>
          
        </CCardFooter>-->
          </CCard>
        </CCol>
      </CCol>
      <CCol col="6">
        <CRow>
          <CCol col="12">
            <CCard>
              <CCardHeader>
                <strong>Impuestos</strong>
              </CCardHeader>
              <CCardBody>
                <CSelect
                  label="Aplica ReteFuentes"
                  placeholder="Aplica ReteFuentes"
                  :options="[
                    { value: 'NO', label: 'NO' },
                    { value: 'SI', label: 'SI' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.aplica_rete_fuente"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CSelect
                  label="Aplica Rete Ica"
                  placeholder="Aplica Rete Ica"
                  :options="[
                    { value: 'NO', label: 'NO' },
                    { value: 'SI', label: 'SI' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.aplica_rete_ica"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol col="12">
            <CCard>
              <CCardHeader>
                <strong>Parametros Configuracion</strong>
              </CCardHeader>
              <CCardBody>
                <CInputCheckbox
                  label="Aplica Todas Las Subcuentas"
                  :value="false"
                  :checked.sync="solo_actualiza_este_usuario"
                />
                <br />
                <CSelect
                  label="Permitir Envio 24 Horas"
                  placeholder="Permitir Envio 24 Horas"
                  :options="[
                    { value: 0, label: 'NO' },
                    { value: 1, label: 'SI' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.envio_24_horas"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CSelect
                  label="Quitar Filtro de Palabras Seguridad Empresarial"
                  placeholder="Quitar Filtro de Palabras Seguridad Empresarial"
                  :options="[
                    { value: 0, label: 'NO' },
                    { value: 1, label: 'SI' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.palabras_empresarial"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CSelect
                  label="Quitar Analisis de Link Dentro del Mensaje"
                  placeholder="Quitar Analisis de Link Dentro del Mensaje"
                  :options="[
                    { value: 0, label: 'NO' },
                    { value: 1, label: 'SI' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.filtro_tiempo_link_inmediato"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CInput
                  label="Respuesta Web Doble Via"
                  :ref="key"
                  v-model="form.Web_Respuesta"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CInput>
                <CInput
                  label="Firma SMS"
                  placeholder="Firma SMS"
                  autocomplete="off"
                  v-model="form.firma_sms"
                  @keyup="limitarCaracteres"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CInput>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol col="12">
            <CCard>
              <CCardHeader>
                <strong>Otros Parametros</strong>
              </CCardHeader>
              <CCardBody>
                <CSelect
                  label="Este Usuario Puede Crear Sub Cuentas (Resseller)"
                  placeholder="Este Usuario Puede Crear Sub Cuentas"
                  :options="[
                    { value: 0, label: 'NO' },
                    { value: 1, label: 'SI' },
                  ]"
                  required
                  was-validated
                  :value.sync="form.crea_subcuentas"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CSelect>
                <CInput
                  label="Notificacion Saldo"
                  placeholder="Notificacion Saldo"
                  autocomplete="off"
                  @keypress="isNumber($event)"
                  minlength="3"
                  required
                  was-validated
                  v-model="form.notifici_saldo"
                >
                  <template #prepend-content
                    ><CIcon name="cil-globe-alt"
                  /></template>
                </CInput>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            Datos Guardado Correctamente.
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </CContainer>
</template>

<script>
export default {
  name: "PSEImpuestosUsuario",
  data() {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      form: {
        tipo_cliente: "",
        nombre_cliente: "",
        tipo_documento: "",
        numero_documento: "",
        digito_verificacion: "",
        celular: "",
        direccion_cliente: "",
        aplica_rete_fuente: "",
        aplica_rete_ica: "",
        crea_subcuentas: "",
        envio_24_horas: "",
        palabras_empresarial: "",
        valor_credito: "",
        Web_Respuesta: "",
        filtro_tiempo_link_inmediato: "",
        genera_factura: "SI",
        notifici_saldo: "",
        firma_sms: "",
      },
      solo_actualiza_este_usuario: false,
    };
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/roles" });
    },
    obtenerUsuarioImpuestoPorId() {
      const id = this.$route.params.id;
      const $self = this;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/usuarios/impuestos/" + id)
        .then((response) => {
          let data = response.data;
          this.form.tipo_cliente = data.Tipo_Cliente;
          this.form.nombre_cliente = data.NombreCliente;
          this.form.tipo_documento = data.TipoDocumento;
          this.form.numero_documento = data.DocumentoCliente;
          this.form.digito_verificacion = data.DigitoVerificaCliente;
          this.form.celular = data.TelefonoCliente;
          this.form.direccion_cliente = data.DireccionCliente;
          this.form.aplica_rete_fuente = data.Aplica_Retefuente;
          this.form.aplica_rete_ica = data.Aplica_Rete_Ica;
          this.form.crea_subcuentas = data.marca_blanca;
          this.form.envio_24_horas = data.fuera_horario;
          this.form.palabras_empresarial = data.filtro_palabras_empresariales;
          this.form.valor_credito = data.valor_credito;
          this.form.Web_Respuesta = data.Web_Respuesta;
          this.form.filtro_tiempo_link_inmediato =
            data.filtro_tiempo_link_inmediato;
          this.form.genera_factura = data.genera_factura;
          this.form.notifici_saldo = data.notificacion_saldo;
          this.form.firma_sms = data.firma_sms;
        })
        .catch((e) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    actualizarUsuario(e) {
      e.preventDefault();
      const id = this.$route.params.id;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/usuarios/impuestos/" + id, {
          Tipo_Cliente: this.form.tipo_cliente,
          NombreCliente: this.form.nombre_cliente,
          TipoDocumento: this.form.tipo_documento,
          DocumentoCliente: this.form.numero_documento,
          DigitoVerificaCliente: this.form.digito_verificacion,
          TelefonoCliente: this.form.celular,
          DireccionCliente: this.form.direccion_cliente,
          Aplica_Retefuente: this.form.aplica_rete_fuente,
          Aplica_Rete_Ica: this.form.aplica_rete_ica,
          marca_blanca: this.form.crea_subcuentas,
          fuera_horario: this.form.envio_24_horas,
          filtro_palabras_empresariales: this.form.palabras_empresarial,
          valor_credito: this.form.valor_credito,
          Web_Respuesta: this.form.Web_Respuesta,
          filtro_tiempo_link_inmediato: this.form.filtro_tiempo_link_inmediato,
          genera_factura: this.form.genera_factura,
          notificacion_saldo: this.form.notifici_saldo,
          solo_actualiza_este_usuario: this.solo_actualiza_este_usuario,
          firma_sms: this.form.firma_sms,
        })
        .then((response) => {
          if (response.data.impuestos) {
            this.mostrarNotificacion++;
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
          }
        })
        .catch((error) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    limitarCaracteres(event) {
      let value = event.target.value;
      event.target.value = this.NumText(value);
      this.form.firma_sms = this.NumText(value);
    },
    NumText(string) {
      //solo letras y numeros
      var out = "";
      //Se añaden las letras validas
      //áéíóúÁÉÍÓÚ
      var filtro =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!¡#$%&/()=?¿[]{}.:,;-_+*@>< ñÑ"; //Caracteres validos

      for (var i = 0; i < string.length; i++)
        if (filtro.indexOf(string.charAt(i)) != -1) out += string.charAt(i);
      return out;
    },
  },
  mounted() {
    setTimeout(() => {
      this.obtenerUsuarioImpuestoPorId();
    }, 100);
  },
};
</script>
